$('document').ready(function() {

    if (window.SURVEY_ACTIVE) {
        var testmode = false;
        var survey_debug = false;



        var urlVars = getUrlVars();


        if (urlVars['debug'] !== undefined && urlVars['debug']) {
            survey_debug = true;
        }

        var s = getRandomInt(1, 100);
        var chance = 100 - parseInt(TNPS_CHANCE);
        if (survey_debug) {
            console.log('TNPS_CHANCE:');
            console.log('chance= ' + TNPS_CHANCE);
            console.log('Roll a dice :');
            console.log('s= ' + s);
            console.log('chance= ' + chance);
            var ccc = getCookie('SurveyAccessed');
            console.log('get cookie: ' + ccc);
            console.log('session cookie: ' , ccc ?'set':'unset');


        }

        // ako je s > chance = 70%, onda upada u 30%, i ako nema session kuki i ako nije test mod
        if (!ccc && (s > chance)) {
            // sad moze da se postavi session kuki:
            setCookie('SurveyAccessed', 'true');
            if (survey_debug) {
                console.log('Session cookie SurveyAccessed set!');
            }

            // pozovi anketu





            setTimeout(function () {
                (function () {
                    function getDeviceModel() {
                        var regExp = /\(([^)]+)\)/;
                        var matches = regExp.exec(navigator.userAgent);
                        var deviceName = '';

                        if (matches.length >= 2) {
                            var userAgendData = matches[1].split(';');
                            if (userAgendData.length >= 3) {
                                deviceName = userAgendData[2];
                            }
                        }

                        return deviceName;
                    }

                    function getBrowserNameAndVersion() {

                        var nAgt = navigator.userAgent;
                        var browserName = navigator.appName;
                        var fullVersion = '' + parseFloat(navigator.appVersion);
                        var majorVersion = parseInt(navigator.appVersion, 10);
                        var nameOffset, verOffset, ix;

                        // In Opera, the true version is after "Opera" or after "Version"
                        if ((verOffset = nAgt.indexOf("Opera")) != -1) {
                            browserName = "Opera";
                            fullVersion = nAgt.substring(verOffset + 6);
                            if ((verOffset = nAgt.indexOf("Version")) != -1)
                                fullVersion = nAgt.substring(verOffset + 8);
                        }
                        // In MSIE, the true version is after "MSIE" in userAgent
                        else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
                            browserName = "Microsoft Internet Explorer";
                            fullVersion = nAgt.substring(verOffset + 5);
                        }
                        // In Chrome, the true version is after "Chrome"
                        else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
                            browserName = "Chrome";
                            fullVersion = nAgt.substring(verOffset + 7);
                        }
                        // In Safari, the true version is after "Safari" or after "Version"
                        else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
                            browserName = "Safari";
                            fullVersion = nAgt.substring(verOffset + 7);
                            if ((verOffset = nAgt.indexOf("Version")) != -1)
                                fullVersion = nAgt.substring(verOffset + 8);
                        }
                        // In Firefox, the true version is after "Firefox"
                        else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
                            browserName = "Firefox";
                            fullVersion = nAgt.substring(verOffset + 8);
                        }
                        // In most other browsers, "name/version" is at the end of userAgent
                        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
                            (verOffset = nAgt.lastIndexOf('/'))) {
                            browserName = nAgt.substring(nameOffset, verOffset);
                            fullVersion = nAgt.substring(verOffset + 1);
                            if (browserName.toLowerCase() == browserName.toUpperCase()) {
                                browserName = navigator.appName;
                            }
                        }
                        // trim the fullVersion string at semicolon/space if present
                        if ((ix = fullVersion.indexOf(";")) != -1)
                            fullVersion = fullVersion.substring(0, ix);
                        if ((ix = fullVersion.indexOf(" ")) != -1)
                            fullVersion = fullVersion.substring(0, ix);

                        majorVersion = parseInt('' + fullVersion, 10);
                        if (isNaN(majorVersion)) {
                            fullVersion = '' + parseFloat(navigator.appVersion);
                            majorVersion = parseInt(navigator.appVersion, 10);
                        }

                        return browserName + ' ' + fullVersion;
                    }

                    function getOSName() {
                        var OSName = "Unknown OS";
                        if (navigator.userAgent.indexOf("Win") !== -1) OSName = "Windows";
                        if (navigator.userAgent.indexOf("Mac") !== -1) OSName = "Macintosh";
                        if (navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";
                        if (navigator.userAgent.indexOf("Android") !== -1) OSName = "Android";
                        if (navigator.userAgent.indexOf("like Mac") !== -1) OSName = "iOS";

                        return OSName;
                    }

                    var OSName = getOSName();
                    var browserNameAndVersion = getBrowserNameAndVersion();
                    var deviceModel = getDeviceModel();
                    var plusMsisdn ='';
                    if (ACTIVE_MSISDN !== "") {
                        plusMsisdn = '+'+ACTIVE_MSISDN;
                    }
                    if (survey_debug) {
                        console.log('VocSignal_SendEvent will be triggered now...');
                    }
                    window.VocSignal_SendEvent('SetParameters', {
                        apiUrl: URL_PROTOCOL + 'a1serbia-astra.sandsiv.com/',
                        customerId: ACTIVE_MSISDN, //
                        language: ACTIVE_LANG.substring(0, 2),
                        //surveyId: 27, // test
                        surveyId: LC['surveryId'], // prod
                        metadata: {
                            'Mobile_Number': ACTIVE_MSISDN, //if available  MSISDN, if not: empty
                            'Country_Code': 'SRB',
                            'Address': plusMsisdn, //if available  MSISDN, if not: empty
                            'Agent_ID': 'Website',
                            'OPCO': 'A1',
                            'Brand': 'A1',
                            'Online_Event': 'Website browsing',
                            'Business_Touchpoint_ID': 'Online',
                            'TAG_Custom_Field_1': OSName,
                            'TAG_Custom_Field_2': browserNameAndVersion,
                            'TAG_Custom_Field_3': deviceModel,
                            'TAG_Custom_Field_4': '',
                            'TAG_Custom_Field_5': ''
                        }
                    });

                    if (survey_debug) {
                        console.log('Triggering VocSignal_SendEvent');
                        console.log('Your OS:', OSName, 'browser:', browserNameAndVersion, 'deviceModel:', deviceModel, 'ACTIVE_MSISDN', ACTIVE_MSISDN, 'ACTIVE_LANG', ACTIVE_LANG);
                    }
                })();

            }, 200);
        } else {
            if (survey_debug) {
                console.log('Session cookie SurveyAccessed exists OR not in 33% chance, did not trigger Survey site VocSignal_SendEvent!');
            }
        }
    }

});